const now = new Date() // 当前日期
const nowDayOfWeek = now.getDay() // 今天本周的第几天
const nowDay = now.getDate() // 当前日
const nowMonth = now.getMonth() // 当前月
const nowYear = now.getFullYear() // 当前年

const DateUtil = {
  /**
   * 获得当前日期：年月日
   *
   * @returns
   */
  getNowDay: function () {
    return this.formatDate(now)
  },
  /**
   * 获得当前日期：年月日时分秒
   *
   * @returns
   */
  getNowDayTime: function () {
    const now = new Date()
    return this.formatDate(now) + ' ' + this.gormatDateAndTime(now)
  },
  /**
   * 获得某一天的年月日时分秒
   *
   * @returns
   */
  getTimeForDate: function (dataObj) {
    const now = new Date(dataObj)
    return this.formatDate(now) + ' ' + this.gormatDateAndTime(now)
  },
  /**
   * 获得本周的开始时间
   *
   * @returns
   */
  getStartDayOfWeek: function () {
    const day = nowDayOfWeek || 7
    return this.formatDate(new Date(nowYear, nowMonth, nowDay + 1 - day))
  },
  /**
   * 获得本周的结束时间
   *
   * @returns
   */
  getEndDayOfWeek: function () {
    const day = nowDayOfWeek || 7
    return this.formatDate(new Date(nowYear, nowMonth, nowDay + 7 - day))
  },
  /**
   * 获得本周的每一天
   *
   * @returns
   */
  getanyDayOfWeek: function (num) {
    const day = nowDayOfWeek || 7
    return this.formatDate(new Date(nowYear, nowMonth, nowDay + num - day))
  },
  /**
   * 获得本月的开始时间
   *
   * @returns
   */
  getStartDayOfMonth: function () {
    const monthStartDate = new Date(nowYear, nowMonth, 1)
    return this.formatDate(monthStartDate)
  },
  /**
   * 获得本月的结束时间
   *
   * @returns
   */
  getEndDayOfMonth: function () {
    const monthEndDate = new Date(nowYear, nowMonth, this.getMonthDays())
    return this.formatDate(monthEndDate)
  },
  /**
   * 获得本月天数
   *
   * @returns
   */
  getMonthDays: function () {
    const monthStartDate = new Date(nowYear, nowMonth, 1)
    const monthEndDate = new Date(nowYear, nowMonth + 1, 1)
    const days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24)
    return days
  },
  /**
   * @param 日期格式化
   * @returns {String}
   */
  formatDate: function (date) {
    const y = date.getFullYear()
    const m = (date.getMonth() + 1).toString().padStart(2, '0')
    const d = date.getDate().toString().padStart(2, '0')
    return `${y}-${m}-${d}`
  },
  /**
   * @param 时间格式化
   * @returns {String}
   */
  gormatDateAndTime: function (date) {
    const h = date.getHours().toString().padStart(2, '0')
    const m = date.getMinutes().toString().padStart(2, '0')
    const s = date.getSeconds().toString().padStart(2, '0')
    return `${h}:${m}:${s}`
  },
  /**
   * 根据日期字符串获取星期几
   * @param dateString 日期字符串（如：2021-12-08）
   * @returns {String}
   */
  getWeek: function (dateString) {
    const dateArray = dateString.split('-')
    const date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2])
    return '周' + '日一二三四五六'.charAt(date.getDay())
  },
  getDateMinus: function (date) {
    var toData = new Date(date).getTime()
    var nowData = new Date().getTime()
    var seconds = toData - nowData
    var days = Math.ceil(seconds / (1000 * 60 * 60 * 24))
    return days
  }
}

export default DateUtil
