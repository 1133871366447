<template>
  <div :class='`commonHeader-wrap flex ${targetPage}`'>
    <!-- left -->
    <div class='left-wrap flex'>
      <div v-if='iconStatus' class='mock' @click='goBack'><i class='el-icon-arrow-left'></i>
      </div>
      <div class='logo-wrap flex' @click='toHomePage' v-if='!comment.statusShow'>
        <img class='logo-icon' src='@/assets/homePage/logo.png' alt='同步课堂'>
        <div :class="['logo-txt', 'PFMC', { 'logo-txt-black-color': leftNavAndBlueBtn }]">同步课堂</div>
      </div>
      <div v-else class='liveInfoCss'>
        <img class='logo-css' src='@/assets/homePage/logo.png' alt='' @click='$router.push("/")'>
        <div>
          <div style='color: #FFFFFF;font-size: 20px'>{{ comment.liveName }}</div>
          <!-- <div style='color: #9FA2B1;font-size: 14px'>{{ comment.startTime | dateFormat }} | {{ comment.teacherName
            }}老师
          </div> -->
        </div>
      </div>
      <div v-if='leftShow' style='display: flex'>
        <!-- <div :class="['nav-bar', 'PFMC', { 'nav-bar-black-color': leftNavAndBlueBtn } ]" @click='openToAbout'>关于我们</div> -->
        <div :class="['nav-bar', 'PFMC', { 'nav-bar-black-color': leftNavAndBlueBtn } ]" @click='openToAbout'>关于谆实</div>
        <div :class="['nav-bar', 'PFMC', { 'nav-bar-black-color': leftNavAndBlueBtn } ]" @click='openToMooc'>了解“慕课西行”
        </div>
        <!-- <div :class="['nav-bar', 'PFMC', { 'nav-bar-black-color': leftNavAndBlueBtn } ]" @click='openToFullView'>
          了解“全景教室”
        </div> -->
        <div :class="['nav-bar', 'PFMC', { 'nav-bar-black-color': leftNavAndBlueBtn } ]" @click='openToFullView'>
          了解“全景教学空间”
        </div>
        <div v-if='leftNavAndBlueBtn || leftTitleAndWhiteBtn'
             :class="['nav-bar', 'PFMC', { 'nav-bar-black-color': leftNavAndBlueBtn } ]" @click='openToApplyUse'>申请接入试用
        </div>
        <!-- <div :class="['nav-bar', 'PFMC', { 'nav-bar-black-color': leftNavAndBlueBtn } ]" @click='openToDownload'>下载客户端
        </div> -->
        <div :class="['nav-bar', 'PFMC', { 'nav-bar-black-color': leftNavAndBlueBtn } ]" @click='openTOprivacyPolicy'>隐私政策
        </div>
      </div>
    </div>
    <!-- 回放页中间部分 -->
    <div v-if="targetPage === 'playback'" class="center-wrap">
      <span style='color: #9FA2B1;font-size: 14px'>{{ comment.startTime | dateFormat }} | {{ comment.teacherName }}老师</span>
    </div>
    <!-- right -->
    <div class='right-wrap flex'>
      <!-- 更多菜单 -->
      <el-popover
        placement='top-end'
        trigger='hover'
        :visible-arrow='false'
        @show='enterMenu'
        @hide='leaveMenu'
      >
        <div class='menu-wrap flex'>
          <div
            class='menu-body flex'
            v-for='(el, i) in menuDatas'
            :key='i'
            @click='goToOther(el.type)'
            :style="{ 'margin-right': i%3 !== 2 ? '16px' : 0 }"
          >
            <img :src='el.img' alt=''>
            <div class='txt PFRC'>{{ el.txt }}</div>
          </div>
        </div>
        <img v-if='!leftNavAndBlueBtn && !leftTitleAndWhiteBtn' class='more-btn'
             :src="isShowMene ? require('@/assets/homePage/moreHover.png') : require('@/assets/homePage/more.png')"
             slot='reference' alt=''>
      </el-popover>
      <!-- 文字编相关： -->
      <template v-if="editBtnShowAboutBackWord">
        <template v-if="!editBtnListShow">
          <div class="editor_btn_container" @click="mutationForSetEditBtnListShow(true)">
            <img class="editor_btn_container_icon" src="https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/5332040fd28e42c3be02185e6b686ec3.png" />
            <p class="editor_btn_container_title">编辑文字</p>
          </div>
        </template>
        <template v-if="editBtnListShow">
          <div class="editor_btn_container editor_btn_container_for_cancel" @click="editCancelForWord">
            <img class="editor_btn_container_icon" src="https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/7ed0808605e0464394c03f55875286d6.png" />
            <p class="editor_btn_container_title">取消编辑</p>
          </div>
          <div class="editor_btn_container editor_btn_container_for_save" @click="saveForTheBackWordEdit">
            <img class="editor_btn_container_icon" src="https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/92b2be496adb47ebac857b25b1150a39.png" />
            <p class="editor_btn_container_title">保存编辑</p>
          </div>
        </template>
      </template>
      <template v-if="!flagForSyncToCourseContainer">
      <!--分享按钮-->
      <el-popover
        v-if='copyStatus && comment.studentInviteCode'
        placement='bottom-end'
        width='300'
        trigger='hover'
        :visible-arrow='false'>
        <div class='commentCss'>
          <div class='com'>回放参加码 <span>{{ comment.studentInviteCode }}</span></div>
          <div class='btnCopy' @click='codeCopy'>复制</div>
        </div>
        <div class='shareCss' slot='reference'>
          <img src='https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/21c9f880cc084fc2bbd9d730e2fd09e7.png'
               alt=''>
          <div>分享</div>
        </div>
      </el-popover>
      <!--共享课 分享-->
      <el-popover
        v-if='copyStatus && !comment.studentInviteCode'
        placement='bottom-end'
        width='300'
        trigger='hover'
        :visible-arrow='false'>
        <div class='commentCss'>
          <div class='com'>复制回放链接 </div>
          <div class='btnCopy' @click='codeCopy'>复制</div>
        </div>
        <div class='shareCss' slot='reference'>
          <img src='https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/21c9f880cc084fc2bbd9d730e2fd09e7.png'
               alt=''>
          <div>分享</div>
        </div>
      </el-popover>
      </template>
      <!-- 同步至课程： -->
      <div class="sync_to_course_container" @click="dialogFlagForSyncToCourseContainer = true" v-if="flagForSyncToCourseContainer">
        <img class="sync_to_course_container_icon" src="https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202210/18ee56e668d445e69032fe28b1849251.png" alt="图标">
        <p class="sync_to_course_container_title">同步至课程</p>
      </div>
      <!-- 已登录 -->
      <el-popover
        class='popover-wrap'
        placement='top-end'
        width='200'
        trigger='hover'
        :visible-arrow='false'
      >
        <div class='loginInfo-wrap'>
          <div class='myInfo-wrap flex'>
            <img class='picture' :src='avatar' alt=''>
            <div class='user-name PFRC'>{{ realname }}</div>
            <div class='school PFRC'>{{ schoolName }}</div>
          </div>
          <div class='exit-wrap exit-wrap-write-off flex'>
            <img src='@/assets/homePage/tuichu.png' alt=''>
            <div class='label PFRC' @click='handleWriteOff'>注销账号</div>
          </div>
          <div class='line-gap'></div>
          <div class='exit-wrap flex'>
            <img src='@/assets/homePage/tuichu.png' alt=''>
            <div class='label PFRC' @click='logoutBtn'>退出登录</div>
          </div>
        </div>
        <div v-show='isLogin' class='login-wrap flex' slot='reference'>
          <img class='picture' :src='avatar' alt=''>
          <img class='downArrow' v-if='!leftTitleAndWhiteBtn && !leftNavAndBlueBtn'
               src='@/assets/homePage/downArrow.png' alt=''>
          <img class='downArrow' v-if='leftTitleAndWhiteBtn' src='@/assets/homePage/downArrow.png' alt=''>
          <img class='downArrow' v-if='leftNavAndBlueBtn' src='@/assets/applyOpen/downArrow.png' alt=''>
        </div>
      </el-popover>

      <!-- 更多功能 -->
      <div v-if='rightHeader' class='functions-list flex'>
        <div @click='joinLive' :class="['function-box', 'flex', { 'writh-box': leftTitleAndWhiteBtn }]">
          <img src='@/assets/applyOpen/jiaruzhibo.png' alt=''>
          <span>加入直播</span>
        </div>
        <div @click='initiateLive' :class="['function-box', 'flex', { 'writh-box': leftTitleAndWhiteBtn }]">
          <img src='@/assets/applyOpen/kaiqizhibo.png' alt=''>
          <span>发起直播</span>
        </div>
        <div @click='experienceAIEditing' :class="['function-box', 'flex', { 'writh-box': leftTitleAndWhiteBtn }]">
          <img src='@/assets/applyOpen/aicut.png' alt=''>
          <span>体验AI剪辑</span>
        </div>
      </div>
      <!-- 未登录 -->
      <el-button v-show='!isLogin' class='submit-btn' type='primary' @click='loginBtn'>登录</el-button>
      <!-- 提示有一堂课的弹框： -->
      <my-dialog
        width='382px'
        pLR='0'
        borderRadius='8px'
        :showMaskFlag='true'
        :dialogFlag='dialogForHaveCourse'
        @hideMask='dialogForHaveCourse = false'
      >
        <div class='dialog_inner_container_for_have_course'>
          <div class='dialog_inner_container_for_have_course_header'>系统提示</div>
          <div class='dialog_inner_container_for_have_course_body'>
            <img class='dialog_inner_container_for_have_course_body_img'
                 src='https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/72bffef3700d4675b001d70a8cf39e7a.png'
                 alt=''>
            <p class='dialog_inner_container_for_have_course_body_title'>
              您有一门课正在进行中，结束后才可开始新课
            </p>
            <p class='dialog_inner_container_for_have_course_body_btn' @click='goToOfHaveCourse'>
              进入课堂
            </p>
          </div>
        </div>
      </my-dialog>
      <!-- 扩展屏幕二维码： -->
      <my-dialog
        :dialogFlag='dialogForOverallSituation'
        width='310px'
        pLR='0'
        borderRadius='8px'
        @hideMask='dialogForOverallSituation = false'
      >
        <div class='dialog_inner_container_for_overall_situation'>
          <div class='dialog_inner_container_for_overall_situationr_header'>扩展屏幕请扫描二维码</div>
          <div class='dialog_inner_container_for_overall_situationr_body'>
            <div class='dialog_inner_container_for_overall_situationr_body_for_qr_code'
                 v-loading='vueQrCodeBoxLoadingForOverallSituation'>
              <vue-qr
                :text='vueQrCodeInfoForOverallSituation'
                :margin='0'
                :size='138'
                :dotScale='1'
              ></vue-qr>
            </div>
          </div>
        </div>
      </my-dialog>

      <my-dialog
        :dialogFlag='dialogFlagForSyncToCourseContainer'
        width='800px'
        pLR='0'
        borderRadius='3px'
        @hideMask='dialogFlagForSyncToCourseContainer = false, openToStudent = true'
        :zIndexForShowBoxFather="2001"
        :showMaskFlag="false"
      >
        <div class="dialog_inner_container_for_sync_to_course_container" v-loading="loadingForConfirmForSync">
          <div class="dialog_inner_container_for_sync_to_course_container_header">
            编辑已完成，一键同步至课程
            <div class="dialog_inner_container_for_sync_to_course_container_header_close_btn" @click="dialogFlagForSyncToCourseContainer = false, openToStudent = true">
              <img src="https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202210/8183c250f9b74176a9c02c69a3ad4de8.png" alt="关闭">
            </div>
          </div>
          <div class="dialog_inner_container_for_sync_to_course_container_body">
            <div class="dialog_inner_container_for_sync_to_course_container_body_left">
              <div class="body_left_header">
                <div class="body_left_header_title">教学资料（{{teachingMaterialList.length}}）</div>
                <div class="check_all_btn">
                  <span style="margin-right: 5px; font-size: 12px; color: #939393;">全选</span>
                  <el-checkbox v-model="checkedForCourseDatum" @change="changeForCheckedForCourseDatum"></el-checkbox>
                </div>
              </div>
              <div class="body_left_body" v-loading="loadingForTeachingMaterialList">
                <ul class="body_left_body_list" v-if="teachingMaterialList.length">
                  <li class="body_left_body_item" v-for="item in teachingMaterialList" :key="item.resourceId">
                    <div class="body_left_body_item_left">
                      <img class="body_left_body_item_left_icon" :src="item.resForIcon" />
                      <p class="body_left_body_item_left_title">{{item.resourceName}}</p>
                    </div>
                    <div class="body_left_body_item_right">
                      <el-checkbox v-model="item.checked"></el-checkbox>
                    </div>
                  </li>
                </ul>
                <div class="body_left_body_list_empty" v-else>未找到教学资料</div>
              </div>
            </div>
            <div class="dialog_inner_container_for_sync_to_course_container_body_right">
              <div class="body_right_header">
                <div class="body_right_header_title">课程（{{courseListForTeachingMaterial.length}}）</div>
                <div class="check_all_btn"></div>
              </div>
              <div class="body_right_body" v-loading="loadingForCourseListForTeachingMaterial">
                <ul class="body_right_body_list" v-if="courseListForTeachingMaterial.length">
                  <li class="body_right_body_item" v-for="item in courseListForTeachingMaterial" :key="item.id">
                    <div class="body_right_body_item_left">
                      <div class="body_right_body_item_left_icon">
                        <img :src="item.cover">
                      </div>
                      <div class="body_right_body_item_left_title">{{item.courseName}}</div>
                    </div>
                    <div class="body_right_body_item_right">
                      <div :class="['body_right_body_item_right_radio', item.courseId === checkedForCourseListForTeachingMaterial ? 'active' : '']" @click="checkedForCourseListForTeachingMaterial = item.courseId"></div>
                    </div>
                  </li>
                </ul>
                <div class="body_right_body_list_empty" v-else>未找到课程</div>
              </div>
            </div>
          </div>
          <div class="dialog_inner_container_for_sync_to_course_container_footer">
            <div class="dialog_inner_container_for_sync_to_course_container_footer_left"></div>
            <div class="dialog_inner_container_for_sync_to_course_container_footer_right">
              <div class="footer_right_top">
                <div :class="['footer_right_top_icon', openToStudent ? 'active' : 'no_active']" @click="openToStudent = !openToStudent"></div>
                <div class="footer_right_top_title">发布并公开给学生</div>
              </div>
              <div :class="['footer_right_bottom', flagForConfirmForSyncCanClick ? 'can_click' : 'can_not_click']" @click="confirmForSync">确定</div>
            </div>
          </div>
        </div>
      </my-dialog>

      <my-dialog
        :dialogFlag='dialogWriteOff'
        width='310px'
        pLR='0'
        borderRadius='8px'
        @hideMask='dialogWriteOff = false'
      >
        <div class="write_off_container">
          <div class="write_off_container_header">注销账号</div>
          <div class="write_off_container_body">
            <div class="body_title">
              <div>如需注销账号</div>
              <div>请致电智慧树运维</div>
            </div>
            <div class="body_sub_title">021-60248622</div>
            <div class="body_btn_outer">
              <div class="body_btn" @click="dialogWriteOff = false">
                确定
              </div>
            </div>
          </div>
        </div>
      </my-dialog>

    </div>
  </div>
</template>

<script>
import myDialog from '../../components/common/myDialog.vue'
import { getType } from '../../utils/toolsForCommon.js'
import VueQr from 'vue-qr'
import moment from 'moment'
import { mapGetters, mapMutations } from 'vuex'
import bus from '@/utils/eventBus'

export default {
  name: 'commonHeader',
  props: {
    targetPage: { // 本组件被引用的页面
      type: String,
      default: ''
    },
    leftNavAndBlueBtn: {
      type: Boolean,
      default: false
    },
    leftTitleAndWhiteBtn: {
      type: Boolean,
      default: false
    },
    iconStatus: {
      type: Boolean,
      default: false
    },
    copyStatus: {
      type: Boolean,
      default: false
    },
    playbackCode: {
      type: String
    },
    leftShow: {
      type: Boolean,
      default: false
    },
    comment: {
      statusShow: { type: String, default: false }
    },
    rightHeader: {
      type: Boolean,
      default: false
    },
    liveId:{
      type:String |Number,
      default:''
    },
    record:{
      type: Number | String,
      default:1
    },
    editBtnShowAboutBackWord: {
      type: Boolean,
      default: false
    },
    flagForSyncToCourseContainer: {
      type: Boolean,
      default: false
    }
  },
  components: {
    myDialog,
    VueQr
  },
  data() {
    return {
      menuDatas: [
        {
          img: 'https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202209/0659bffaf54746bc90ede97a887747fe.png',
          txt: '我的直播',
          type: 'fullScreenCourse'
        },
        {
          img: require('../../assets/homePage/pingmuhuixian.png'),
          txt: '屏幕回显',
          type: 'screenEcho'
        },
        {
          img: require('../../assets/homePage/guanliyuan.png'),
          txt: '管理员登录',
          type: 'administratorLogin'
        }
      ],
      isLogin: false,
      isShowMene: false,
      avatar: '',
      realname: '',
      schoolName: '',
      goToOfHaveCourseType: '',
      goToOfHaveCourseOfLiveId: '',
      dialogForHaveCourse: false,
      rtData: {},
      dialogForOverallSituation: false,
      vueQrCodeBoxLoadingForOverallSituation: false,
      vueQrCodeInfoForOverallSituation: '',
      getQrCodeTimerIdForOverallSituation: 0,
      getInfoByQrCodeTimerIdForOverallSituation: 0,
      InfoByQrCodeForOverallSituation: null,
      QRCodeInfo: '',
      imUserInfo: {
        nickName: '我',
        avatar: 'https://image.zhihuishu.com/zhs/ablecommons/demo/201804/b43ff1ee189f4879ae115873d2079cd9.jpg'
      },
      dialogFlagForSyncToCourseContainer: false,
      checkedForCourseDatum: false,
      openToStudent: true,
      teachingMaterialList: [],
      courseListForTeachingMaterial: [],
      checkedForCourseListForTeachingMaterial: '',
      loadingForTeachingMaterialList: false,
      loadingForCourseListForTeachingMaterial: false,
      loadingForConfirmForSync: false,
      dialogWriteOff: false
    }
  },
  watch: {
    InfoByQrCodeForOverallSituation: {
      handler(newValue) {
        if (getType(newValue) === 'Object') {
          this.$message.success('扫码成功，即将跳转')
          setTimeout(() => {
            const { role } = newValue
            const liveId = String(newValue.mainLiveId || newValue.liveId)
            let routerPathName = ''
            // 新增lc模式直播，需有相应判断：
            if (Object.prototype.hasOwnProperty.call(newValue, 'liveCourseId')) {
              switch (role) {
                case '1':
                case '2':
                  routerPathName = 'OverallSituationForLC'
                  break
              }
            } else {
              // role 1主讲扫码 2助教扫码
              switch (role) {
                case '1':
                case '2':
                  routerPathName = 'OverallSituation'
                  break
              }
            }
            // 针对扩展屏幕回显：
            this.getInfoByQrCodeTimerIdForOverallSituation && clearInterval(this.getInfoByQrCodeTimerIdForOverallSituation)
            this.getQrCodeTimerIdForOverallSituation && clearInterval(this.getQrCodeTimerIdForOverallSituation)
            localStorage.removeItem('vueQrCodeInfo_local_for_overall_situation')
            this.dialogForOverallSituation = false
            window.open(`https://www.livecourse.com/teacher/${routerPathName}?liveId=${liveId}`, '_blank')
          }, 500)
        }
      },
      immediate: true
    },
    teachingMaterialList: {
      handler (newValue) {
        if (!newValue.length) return
        const checkedLength = newValue.filter(item => item.checked).length
        if (this.teachingMaterialList.length === checkedLength) {
          this.checkedForCourseDatum = true
        } else {
          this.checkedForCourseDatum = false
        }
      },
      immediate: true,
      deep: true
    },
    dialogFlagForSyncToCourseContainer: {
      handler (newVal) {
        if (newVal) {
          this.getHomeResourceList()
          this.getHomeFlippedCourseList()
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.init()
    localStorage.setItem("gobackStatus",this.record)
  },
  methods: {
    ...mapMutations('common', {
      mutationForSetEditBtnListShow: 'mutationForSetEditBtnListShow'
    }),
    editCancelForWord () {
      this.mutationForSetEditBtnListShow(false)
      bus.$emit('operationForEditBtnFlagChangeForWord', false)
    },
    async init() {
      const res = await this.$http.getLoginUserInfo()
      if (res.data.code != 200) {
        this.isLogin = false
        // 删除状态管理和本地存储
        this.$store.commit('userInfo/Mutation_SET_ISLOGIN', false)
        this.$store.commit('userInfo/Mutation_REMOVE_USERINFO')
        // 如果是直播回放详情页面是需要自动登录的：
        if (this.$route.name === 'playback') {
          this.loginBtn()
        }
      } else {
        // 已登录
        // 添加状态管理并存储在本地
        this.$store.commit('userInfo/Mutation_SET_ISLOGIN', true)
        this.$store.commit('userInfo/Mutation_SET_USERINFO', res.data.rt)
        this.loadingUserInfo()
        this.isLogin = true
      }
    },

    //返回按钮
    goBack(){
      console.log(localStorage.getItem('gobackStatus'));
      if(localStorage.getItem('gobackStatus')==2){
        this.$router.push('/historyLive')
      }else{
        this.$router.push('/')
      }
    },

    //复制功能
    codeCopy() {
      if(this.comment.studentInviteCode){
        var res = `"${this.comment.liveName}"课堂回放观看方式\n打开"同步课堂网"：https://www.livecourse.com/home\n点击【加入直播】，输入回放参加码：${this.comment.studentInviteCode}`

      }else{
        var res = `"${this.comment.liveName}"课堂回放观看方式\n回放链接：${window.location.href}`
      }
      // var res = `https://www.livecourse.com/playback?id=${this.liveId}`
      const textarea = document.createElement('textarea')
      document.body.appendChild(textarea)	//	添加到body中
      textarea.value = res	//	给dom设置值
      textarea.select()	//	设置选中
      const copyFalse = document.execCommand('copy')
      this.$message.success('复制成功!')
      textarea.remove()	//	用完移除dom
    },
    loadingUserInfo() {
      const localDatas = JSON.parse(localStorage.getItem('User_userInfo'))
      this.avatar = localDatas.avatar || ''
      this.realname = localDatas.realname || '- -'
      this.schoolName = localDatas.schoolName || '- -'
    },
    enterMenu() {
      this.isShowMene = true
    },
    leaveMenu() {
      this.isShowMene = false
    },
    toHomePage() {
      if (this.$route.path !== '/home') {
        this.$router.push('/')
      }
    },
    openToMooc() {
      window.open('https://www.zhihuishu.com/columnsapp/student/MukeWestbound/home.html', '_blank')
    },
    openToDownload() {
      // window.open('https://www.zhihuishu.com/DownloadApp.html', '_blank')
      window.open('https://www.livecourse.com/teacher/downloadLiveCourseClient', '_blank')
    },
    openToAbout() {
      // window.open('https://hike-doc-online-h5.zhihuishu.com/alweb.html?WOPISrc=https://file.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/5273e04a07ad45a488f1b96f923e5b58.pdf', '_blank')
      window.open('https://hike-doc-online-h5.zhihuishu.com/alweb.html?WOPISrc=https://file.zhihuishu.com/zhs/aidedteaching/COURSE_FOLDER/202404/ea93e5605ce34a5c929d4714dd02bec0.pptx', '_blank')
    },
    openToFullView() {
      // window.open('https://hike-doc-online-h5.zhihuishu.com/alweb.html?WOPISrc=https://file.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/f264e24bc99b459886f60ae1f5ec19c0.pdf', '_blank')
      window.open('https://hike-doc-online-h5.zhihuishu.com/alweb.html?WOPISrc=https://file.zhihuishu.com/zhs/aidedteaching/COURSE_FOLDER/202404/a2aa78791bf14db3890621eaa0df5109.pdf', '_blank')
    },
    openToApplyUse() {
      this.$router.push('/applyOpen')
    },
    openTOprivacyPolicy() {
      window.open('https://www.zhihuishu.com/columnsapp/student/UserPrivacyProtocol/PrivacyPolicy1.html', '_blank')
    },
    openToHistoryLive() {
      const localDatas = JSON.parse(localStorage.getItem('User_userInfo'))
      if (localDatas) {
        this.$router.push('/historyLive')
      } else {
        let resultUrl = location.href
        location.href = `https://www.livecourse.com/api/simple-class/zhs/redirectUrlAndGetUserInfo?redirectUrl=${resultUrl}`
      }
    },
    goToOther(type) {
      switch (type) {
        case 'screenEcho':
          // window.open('https://www.livecourse.com/teacher/Home?typeForCreate=join&screenreturnshow=1', '_blank')
          this.goOverallSituation()
          break
        case 'administratorLogin':
          window.open('https://www.livecourse.com/school/#/login', '_blank')
          break
        case 'fullScreenCourse':
          // window.open('https://www.livecourse.com/homepage/', '_blank')
          this.openToHistoryLive()
          break
      }
    },
    // 屏幕回显
    async goOverallSituation() {
      this.dialogForOverallSituation = true
      this.vueQrCodeBoxLoadingForOverallSituation = true
      this.vueQrCodeInfoForOverallSituation = await this.getQrCodeForOverallSituation()
      this.getQrCodeTimerIdForOverallSituation = setInterval(async () => {
        this.vueQrCodeInfoForOverallSituation = await this.getQrCodeForOverallSituation()
      }, 10000)
      this.vueQrCodeBoxLoadingForOverallSituation = false
      // 获取到二维码之后轮询，看app是否扫码完成：
      this.getInfoByQrCodeTimerIdForOverallSituation = setInterval(async () => {
        const qrcode = this.vueQrCodeInfoForOverallSituation.split('qrCode=')[1]
        const resultReally = await this.getInfoByQrCode(qrcode)
        if (resultReally.isScan) {
          // 监控是否成功并跳转
          this.InfoByQrCodeForOverallSituation = resultReally
          clearInterval(this.getInfoByQrCodeTimerIdForOverallSituation)
        }
      }, 3000)
    },
    async getInfoByQrCode(qrcode) {
      const { data: result } = await this.$http.getInfoByQrCode({ qrcode })
      if (result.code !== 200) return this.$message.error(result.msg)
      const { rt: resultReally } = result
      if (resultReally.resultStatus !== 1) {
        return this.$message.error(resultReally.resultMessage)
      }
      this.QRCodeInfo = resultReally
      // 扫码成功才设置信息 start：
      if (resultReally.isScan) {
        this.imUserInfo.nickName = resultReally.userInfo.nickname
        this.imUserInfo.avatar = resultReally.userInfo.icon
        localStorage.setItem('scanUserInfo', JSON.stringify({
          nickName: resultReally.userInfo.nickname,
          avatar: resultReally.userInfo.icon
        }))
      }
      // 扫码成功才设置信息 end
      return resultReally
    },
    async getQrCodeForOverallSituation() {
      let vueQrCodeInfo = ''
      const vueQrCodeInfoLocal = localStorage.getItem('vueQrCodeInfo_local_for_overall_situation')
      if (vueQrCodeInfoLocal) {
        vueQrCodeInfo = vueQrCodeInfoLocal
      } else {
        const { data: result } = await this.$http.generateScreenQrCode({ qrcodeType: 2 })
        if (result.code !== 200) return this.$message.error(result.msg)
        const { rt: resultReally } = result
        if (resultReally.resultStatus !== 1) return this.$message.error(resultReally.resultMessage)
        vueQrCodeInfo = resultReally.qrCodeUrl
        // 进行缓存
        localStorage.setItem('vueQrCodeInfo_local_for_overall_situation', vueQrCodeInfo, { exp: 600 })
      }
      return vueQrCodeInfo
    },
    // 登录
    loginBtn() {
      // let resultUrl = location.href.split('?')[0]
      // resultUrl = resultUrl.lastIndexOf('/') === resultUrl.length - 1 ? resultUrl.slice(0, resultUrl.lastIndexOf('/')) : resultUrl
      let resultUrl = location.href
      location.href = `https://www.livecourse.com/api/simple-class/zhs/redirectUrlAndGetUserInfo?redirectUrl=${resultUrl}`
    },
    // 注销账号
    handleWriteOff () {
      this.dialogWriteOff = true
    },
    // 登出
    logoutBtn() {
      localStorage.removeItem('User_userInfo')
      const resultUrl = location.href
      location.href = `https://www.livecourse.com/api/simple-class/zhs/logout?redirectUrl=${resultUrl}`
    },
    joinLive() {
      window.open('https://www.livecourse.com/teacher/Home?typeForCreate=join', '_self')
    },
    async initiateLive() {
      if (!localStorage.getItem('User_userInfo')) {
        let resultUrl = location.href.split('?')[0]
        resultUrl = resultUrl.lastIndexOf('/') === resultUrl.length - 1 ? resultUrl.slice(0, resultUrl.lastIndexOf('/')) : resultUrl
        location.href = `https://www.livecourse.com/api/simple-class/zhs/redirectUrlAndGetUserInfo?redirectUrl=${resultUrl}`
      } else {
        // authType 1学校 2企业 3培训机构   roleList 学校<1学生 2老师> 企业<1员工 2老师 3管理员>
        if (!JSON.parse(localStorage.getItem('User_userInfo')).authInfo) return this.$message.error('抱歉，您暂无权限')
        const authTypeItemForSchool = JSON.parse(localStorage.getItem('User_userInfo')).authInfo.find(item => item.authType === 1)
        if (!authTypeItemForSchool) this.$message.error('抱歉，您暂无权限')
        if (!(authTypeItemForSchool.roleList || []).includes(2)) return this.$message.error('拥有老师权限才能发起直播，请联系管理员开通！')
        const { data } = await this.$http.teacherQuickLive()
        if (data.code !== 200) return this.$message.error(data.msg)
        const { rt } = data
        if (rt.resultStatus !== 1 && rt.resultStatus !== 2) return this.$message.error(rt.resultMessage)
        this.rtData = rt
        switch (rt.resultStatus) {
          case 1: // 创建直播成功
            window.open(`https://www.livecourse.com/teacher/StudentPicture?liveId=${rt.liveId}&sourceofcome=sourcefromweb`, '_blank')
            break
          case 2: // 您有一门正在进行中的课
            if (rt.role === 1) {
              if (Object.prototype.hasOwnProperty.call(rt, 'liveCourseId')) {
                this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLEONEFORLC'
              } else {
                this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLEONE'
              }
            } else if (rt.role === 2) {
              if (Object.prototype.hasOwnProperty.call(rt, 'liveCourseId')) {
                this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLETWOFORLC'
              } else {
                this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLETWO'
              }
            }
            this.dialogForHaveCourse = true
            this.goToOfHaveCourseOfLiveId = rt.liveId
            break
        }
      }
    },
    goToOfHaveCourse() {
      let name = ''
      switch (this.goToOfHaveCourseType) {
        case 'HAVECOURSEFORCREATEOFROLEONE':
        case 'HAVECOURSEFORJOINOFROLEONE':
          name = 'StudentPicture'
          break
        case 'HAVECOURSEFORCREATEOFROLETWO':
        case 'HAVECOURSEFORJOINOFROLETWO':
          name = 'TeacherPicture'
          break
        case 'HAVECOURSEFORJOINOFROLEONEFORLC':
          name = 'StudentPictureForLC'
          break
        case 'HAVECOURSEFORJOINOFROLETWOFORLC':
          name = 'TeacherPictureForLC'
          break
      }
      window.open(`https://www.livecourse.com/teacher/${name}?liveId=${this.goToOfHaveCourseOfLiveId}&sourceofcome=sourcefromweb`, '_blank')
    },
    experienceAIEditing () {
      this.$router.push('/AIExperience')
    },
    saveForTheBackWordEdit () {
      this.$parent.$refs.refForAudioAIInPlayBack.saveForTheBackWordEdit(() => {
        this.dialogFlagForSyncToCourseContainer = true
      })
    },
    async confirmForSync () {
      if (!this.flagForConfirmForSyncCanClick) return
      const params = {
        liveId: this.$route.query.id,
        courseId: this.checkedForCourseListForTeachingMaterial,
        isPublish: this.openToStudent ? 1 : 0,
        resourceList: this.teachingMaterialList.filter(item => item.checked)
      }
      this.loadingForConfirmForSync = true
      const { data: result } = await this.$http.homeSyncResource(params)
      this.loadingForConfirmForSync = false
      if (result.code !== 200) return this.$message.error(result.msg)
      if (!result.rt) {
        this.dialogFlagForSyncToCourseContainer = false
        return this.$message.error('同步失败')
      }
      this.$message.success('同步成功')
      this.dialogFlagForSyncToCourseContainer = false
      this.checkedForCourseListForTeachingMaterial = ''
      this.openToStudent = true
    },
    // 获取直播下的资源文件列表:
    async getHomeResourceList () {
      try {
        const id = this.$route.query.id
        this.loadingForTeachingMaterialList = true
        const { data: result } = await this.$http.homeResourceList({ liveId: id })
        this.loadingForTeachingMaterialList = false
        if (result.code !== 200) return this.$message.error(result.msg)
        this.teachingMaterialList = (result.rt || []).map(item => {
          let resForIcon = ''
          switch (item.resourceType) {
            case 1:
            case 2:
            case 4:
              resForIcon = 'https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202210/bebd018cd80b44c885b20fda9e59279b.png'
              break
            case 3:
              resForIcon = 'https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202210/9e4ae3592c8242c5a6c99e6c3ba55c26.png'
              break
          }
          return {
            ...item,
            checked: true,
            resForIcon
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    // 获取主讲老师下的翻转课程列表:
    async getHomeFlippedCourseList () {
      try {
        this.loadingForCourseListForTeachingMaterial = true
        const { data: result } = await this.$http.homeFlippedCourseList()
        this.loadingForCourseListForTeachingMaterial = false
        if (result.code !== 200) return this.$message.error(result.msg)
        this.courseListForTeachingMaterial = result.rt || []
      } catch (err) {
        console.log(err)
      }
    },
    changeForCheckedForCourseDatum () {
      if (this.checkedForCourseDatum) {
        this.teachingMaterialList = this.teachingMaterialList.map(item => {
          return {
            ...item,
            checked: true
          }
        })
      } else {
        this.teachingMaterialList = this.teachingMaterialList.map(item => {
          return {
            ...item,
            checked: false
          }
        })
      }
    },
    // 专门给父组件使用：
    dialogFlagForSyncToCourseContainerTrue () {
      this.dialogFlagForSyncToCourseContainer = true
    }
  },
  filters: {
    //日期时间转化过滤器
    dateFormat(str, def = 'YYYY-MM-DD HH:mm:ss') {
      return moment(str).format(def)
    }
  },
  computed: {
    ...mapGetters('common', ['editBtnListShow']),
    flagForConfirmForSyncCanClick: function () {
      const checkedLength = this.teachingMaterialList.filter(item => item.checked).length
      return checkedLength && this.checkedForCourseListForTeachingMaterial
    }
  }
}
</script>

<style lang='less' scoped>
.commonHeader-wrap {
  width: 100%;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  position: relative;
  &.playback{
    height: 60px;
  }
  // left
  .left-wrap {
    align-items: center;

    .mock {
      color: #FFFFFF;
      font-size: 25px;
      cursor: pointer;
      margin-right: 20px;

      &:hover {
        color: #dddddd;
      }
    }

    .logo-wrap {
      cursor: pointer;
      margin-right: 32px;
      align-items: center;

      .logo-icon {
        width: 40px;
        height: 40px;
      }

      .logo-txt {
        font-size: 24px;
        font-weight: 500;
        color: #FFFFFF;
        margin-left: 8px;
      }

      .logo-txt-black-color {
        color: #000001;
      }
    }

    .nav-bar {
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
      margin-right: 20px;

      &:hover {
        color: #1672ff;
      }
    }

    .nav-bar-black-color {
      color: #1F2329;
    }
  }
  .center-wrap{
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    transform: translateX(-50%) ;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // right
  .right-wrap {
    align-items: center;
    .editor_btn_container {
      width: 112px;
      height: 40px;
      border-radius: 4px;
      border: 2px solid #FFFFFF;
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      font-size: 14px;
      user-select: none;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      .editor_btn_container_icon {
        width: 24px;
        height: 24px;
        display: block;
        margin-right: 8px;
      }
    }
    .editor_btn_container_for_cancel,
    .editor_btn_container_for_save {
      border-color: #95F204;
      color: #95F204;
    }
    .shareCss {
      width: 96px;
      height: 40px;
      background-color: #1672FF;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: #3b74fc;
        color: #dddddd;
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }

    .more-btn {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }

    ::v-deep .submit-btn {
      width: 96px;
      height: 40px;
      border: 0;
      border-radius: 4px;
      background: #1672FF;
      margin-left: 20px;

      &:hover {
        background: #3283FF;
      }

      span {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 14px;
      }
    }

    // 更多功能
    .functions-list {
      .function-box {
        width: 113px;
        height: 40px;
        border-radius: 4px;
        border: 2px solid #1672FF;
        cursor: pointer;
        margin-left: 20px;
        justify-content: center;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1672FF;
        }

        &:hover {
          border: 2px solid #5296FF;
          background-color: #F1F7FF;
        }
      }

      .writh-box {
        border: 2px solid #fff;

        span {
          color: #fff;
        }

        &:hover {
          border: 2px solid #1672FF;
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}

// hover菜单
.menu-wrap {
  margin: 8px;

  .menu-body {
    width: 88px;
    height: 88px;
    border-radius: 4px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #ECEFF2;
    }

    img {
      width: 40px;
      height: 40px;
    }

    .txt {
      font-size: 14px;
      font-weight: 400;
      color: #1E2228;
      line-height: 20px;
    }
  }
}

// 已登录
.login-wrap {
  align-items: center;
  cursor: pointer;
  margin-left: 20px;

  .picture {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .downArrow {
    width: 24px;
    height: 24px;
  }
}

// 登录弹框
.loginInfo-wrap {
  height: 150px;
  .myInfo-wrap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 28px;

    .picture {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    .user-name {
      font-size: 14px;
      font-weight: 400;
      color: #1E2228;
      margin: 8px 0;
      line-height: 14px;
    }

    .school {
      font-size: 12px;
      font-weight: 400;
      color: #9FA2B1;
      line-height: 1.4;
      overflow:hidden;
      text-overflow:ellipsis;
      display:-webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient:vertical;
      max-height:33px;
    }
  }

  .line-gap {
    width: 100%;
    height: 1px;
    background: #EFF3F7;
    position: absolute;
    left: 0;
    bottom: 30px;
  }

  .exit-wrap {
    width: 100%;
    height: 30px;
    align-items: center;
    cursor: pointer;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 0 0 5px 5px;

    &:hover {
      background-color: #F5F5F5;
    }

    img {
      width: 16px;
      height: 16px;
      margin-left: 16px;
    }

    .label {
      font-size: 14px;
      font-weight: 400;
      color: #1E2228;
      margin-left: 8px;
    }
  }
  .exit-wrap-write-off {
    position: absolute;
    left: 0;
    bottom: 30px;
  }
}

// 发起直播弹框
.dialog_inner_container_for_have_course {
  padding: 20px 17px;
  padding-bottom: 25px;

  .dialog_inner_container_for_have_course_header {
    width: 100%;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1E2228;
  }

  .dialog_inner_container_for_have_course_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialog_inner_container_for_have_course_body_img {
      width: 150px;
      height: 105px;
      display: block;
      margin-top: 20px;
    }

    .dialog_inner_container_for_have_course_body_title {
      width: 232px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2A2A2A;
      line-height: 20px;
      margin-top: 32px;
    }

    .dialog_inner_container_for_have_course_body_btn {
      width: 104px;
      height: 40px;
      background: #1672FF;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;
      user-select: none;

      &:hover {
        background: #1367e6;
      }

      &:active {
        background: #1672FF;
      }
    }
  }
}

.dialog_inner_container_for_overall_situation {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 40px;

  .dialog_inner_container_for_overall_situationr_header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0C0B22;
  }

  .dialog_inner_container_for_overall_situationr_body {
    width: 100%;
    height: auto;
    padding: 10px 0 100px;

    .dialog_inner_container_for_overall_situationr_body_for_qr_code {
      width: 140px;
      height: 140px;
      background: #ffffff;
      border: 1px solid #ededed;
      margin: 0 auto;
      overflow: hidden;
    }
  }
}

//分享css
.commentCss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  .com {

    color: #9FA2B1;

    span {
      color: #1E2228;
    }
  }

  .btnCopy {
    color: #FFFFFF;
    width: 40px;
    height: 28px;
    background-color: #1672FF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
      color: #dddddd;
      background-color: #3b74fc;
    }
  }
}

//header左侧信息
.liveInfoCss {
  display: flex;
  align-items: center;

  .logo-css {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    cursor: pointer;
  }
}
// 新需求：同步至课程：
.sync_to_course_container {
  width: 135px;
  height: 40px;
  border-radius: 4px;
  background-color: #1672ff;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: #4a6de0;
  }
  .sync_to_course_container_icon {
    width: 24px;
    height: 24px;
    display: block;
    margin-right: 8px;
  }
}
.dialog_inner_container_for_sync_to_course_container {
  width: 100%;
  height: auto;
  .dialog_inner_container_for_sync_to_course_container_header {
    width: 100%;
    height: 55px;
    line-height: 55px;
    background-image: linear-gradient(to bottom, #7A97F1, #B388F6);
    font-size: 18px;
    color: #FFF;
    padding: 0 20px;
    position: relative;
    .dialog_inner_container_for_sync_to_course_container_header_close_btn {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  .dialog_inner_container_for_sync_to_course_container_body {
    width: 100%;
    height: 370px;
    display: flex;
    .body_left_body_list_empty,
    .body_right_body_list_empty {
      width: 100%;
      height: 100%;
      background-color: #F8F8F8;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #BBB;
    }
    .dialog_inner_container_for_sync_to_course_container_body_left {
      flex: 1;
      padding: 17px 20px;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #EEEEEE;
      /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #1672FF;
        border-color: #1672FF;
      }
      .body_left_header {
        .body_left_header_title {
          width: 100%;
          height: 20px;
          color: #4B4C4C;
        }
        .check_all_btn {
          width: 100%;
          height: 18px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 10px;
        }
      }
      .body_left_body {
        flex: 1;
        width: 100%;
        overflow-y: auto;
        .body_left_body_list {
          width: 100%;
          .body_left_body_item {
            width: 100%;
            height: 55px;
            border-bottom: 1px solid #EEEEEE;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .body_left_body_item_left {
              flex: 1;
              width: 300px;
              display: flex;
              align-items: center;
              .body_left_body_item_left_icon {
                width: 20px;
                height: 25px;
                display: block;
              }
              .body_left_body_item_left_title {
                width: 100%;
                font-size: 12px;
                color: #666666;
                margin-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
    .dialog_inner_container_for_sync_to_course_container_body_right {
      flex: 1;
      padding: 17px 20px;
      display: flex;
      flex-direction: column;
      .body_right_header {
        .body_right_header_title {
          width: 100%;
          height: 20px;
          color: #4B4C4C;
        }
        .check_all_btn {
          width: 100%;
          height: 18px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 10px;
        }
      }
      .body_right_body {
        flex: 1;
        width: 100%;
        overflow-y: auto;
        .body_right_body_list {
          width: 100%;
          .body_right_body_item {
            width: 100%;
            height: 55px;
            border-bottom: 1px solid #EEEEEE;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .body_right_body_item_left {
              flex: 1;
              width: 300px;
              display: flex;
              align-items: center;
              .body_right_body_item_left_icon {
                width: 65px;
                height: 35px;
                background-color: #F5F5F5;
                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }
              .body_right_body_item_left_title {
                width: 100%;
                font-size: 12px;
                color: #666666;
                margin-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .body_right_body_item_right {
              .body_right_body_item_right_radio {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                border: 1px solid #E3E3EE;
                cursor: pointer;
                position: relative;
                &.active {
                  border: 1px solid #1672FE;
                  &::before {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #1672FE;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .dialog_inner_container_for_sync_to_course_container_footer {
    width: 100%;
    height: 95px;
    display: flex;
    .dialog_inner_container_for_sync_to_course_container_footer_left,
    .dialog_inner_container_for_sync_to_course_container_footer_right {
      flex: 1;
    }
    .dialog_inner_container_for_sync_to_course_container_footer_right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .footer_right_top {
        display: flex;
        align-items: center;
        .footer_right_top_icon {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 10px;
          box-shadow: 0 0 5px #EEE;
          cursor: pointer;
          &.active {
            background-color: #1672FE;
          }
          &.no_active {
            border: 1px solid #969696;
          }
        }
        .footer_right_top_title {
          font-size: 12px;
          color: #969696;
        }
      }
      .footer_right_bottom {
        width: 116px;
        height: 40px;
        color: #FFF;
        border-radius: 5px;
        font-size: 14px;
        line-height: 40px;
        text-align: center;
        margin-top: 10px;
        user-select: none;
        &.can_click {
          background-color: #1672FE;
          cursor: pointer;
          &:hover {
            background-color: #4c6dda;
          }
        }
        &.can_not_click {
          background-color: #ccc;
          cursor: not-allowed;
          &:hover {
            background-color: #ccc;
          }
        }
      }
    }
  }
}
.write_off_container {
  width: 100%;
  height: auto;
  .write_off_container_header {
    width: 100%;
    height: 70px;
    line-height: 70px;
    padding-left: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #222222;
    border-bottom: 1px solid #E7E7E7;
  }
  .write_off_container_body {
    .body_title {
      width: 100%;
      padding: 30px 0;
      text-align: center;
      color: #2E3E50;
    }
    .body_sub_title {
      width: 100%;
      height: auto;
      font-size: 25px;
      font-weight: 600;
      text-align: center;
      color: #6061D4;
    }
    .body_btn_outer {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      padding: 20px 0 40px;
    }
    .body_btn {
      width: 120px;
      height: 35px;
      background-color: #6061D4;
      color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: #5455b4;
      }
      &:active {
        background-color: #6061D4;
      }
    }
  }
}
</style>
