<template>
  <div>
    <transition name="myself-dialog-fade">
      <div class="show_box_father" v-if="dialogFlag" :style="{ zIndex: zIndexForShowBoxFather }">
        <div
          class="show_box_son"
          @click.stop
          :style="{ width, padding: pLR, borderRadius }"
        >
          <div
            v-if="showMaskFlag"
            class="close_icon ZHIHUISHU_QZMD"
            @click="$emit('hideMask')"
          >
            <img
              src="//image.zhihuishu.com/zhs/ablecommons/zhangying/202110/322b44f4a1e34d8cb527d1e033ec659b.png"
              alt="关闭"
            />
          </div>
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'myDialog',
  props: {
    dialogFlag: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '1200px'
    },
    pLR: {
      type: String,
      default: '0 20px'
    },
    borderRadius: {
      type: String,
      default: '10px'
    },
    showMaskFlag: {
      type: Boolean,
      default: true
    },
    zIndexForShowBoxFather: {
      type: Number,
      default: 1000
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.show_box_father {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;
  .show_box_son {
    height: auto;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    .close_icon {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 25px;
      right: 31px;
      img {
        width: inherit;
        height: inherit;
        cursor: pointer;
      }
    }
  }
}

.myself-dialog-fade-enter-active {
  animation: dialog-fadein 0.4s;
  .show_box_son {
    animation: dialog-zoom 0.4s;
  }
}

.myself-dialog-fade-leave-active {
  animation: dialog-fadeout 0.4s;
  .show_box_son {
    animation: dialog-out 0.4s;
  }
}

@keyframes dialog-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dialog-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes dialog-zoom {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes dialog-out {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  100% {
    transform: translate(-50%, -50%) scale(0);
  }
}
</style>
